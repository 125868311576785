import axios from "axios";
import {
  setAccessToken,
  getAccessToken,
  setRefreshToken,
  getRefreshToken,
  clearLocalStorage,
} from "helpers/auth";
import { Deserializer } from "jsonapi-serializer";

function getAxiosInstance() {
  const instance = axios.create();
  const accessToken = getAccessToken();
  if (accessToken) {
    instance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  }

  instance.defaults.baseURL = process.env.REACT_APP_API_URL;

  instance.interceptors.response.use(
    (response) => {
      const { access, refresh } = response.data?.data?.attributes || {};
      if (access) {
        setAccessToken(access);
      }
      if (refresh) {
        setRefreshToken(refresh);
      }
      return response;
    },
    (error) => {
      const refreshToken = getRefreshToken();
      if (
        error.response.status === 401 &&
        error.response.data.error === "Invalid or expired access token" &&
        refreshToken
      ) {
        return axios
          .post(
            "/thermal_admin_refresh",
            {},
            {
              baseURL: process.env.REACT_APP_API_URL,
              headers: { "X-Refresh-Token": refreshToken },
            }
          )
          .then((response) => {
            const newAccessToken = response.data.data.attributes.access;
            setAccessToken(newAccessToken);
            error.response.config.headers.Authorization = `Bearer ${newAccessToken}`;
            return axios(error.response.config);
          })
          .catch(() => {
            clearLocalStorage();
            // TODO: can this be done with history.push instead of refresh page?
            return (window.location.href = "/login");
          });
      }
      // for normal error
      return Promise.reject(error);
    }
  );
  return instance;
}

function buildFormData(formData, data, parentKey) {
  // react dropzone represents uploaded files as array of individual files (even if it's only 1)
  if (
    data &&
    typeof data === "object" &&
    !(data instanceof Date) &&
    !(data instanceof File) &&
    !(data instanceof FileList)
  ) {
    if (data instanceof Array && data.length === 0) {
      formData.append(`${parentKey}[]`, []);
    } else if (data instanceof Array && typeof data[0] === "string") {
      for (let idx = 0; idx < data.length; idx += 1) {
        formData.append(`${parentKey}[]`, data[idx]);
      }
    } else {
      Object.keys(data).forEach((key) => {
        buildFormData(
          formData,
          data[key],
          parentKey ? `${parentKey}[${key}]` : key
        );
      });
    }
  } else {
    let value = data;
    // regular file upload represented as filelist with 1 file
    if (data instanceof FileList) {
      data.length > 0 ? (value = data[0]) : (value = null);
    }
    if (value !== null) formData.append(parentKey, value);
  }
}

async function callAPI(url, method = "get", data = undefined, meta = false) {
  // use FormData to support file uploads
  const formData = new FormData();
  if (data) buildFormData(formData, data);

  try {
    const response = await getAxiosInstance().request({
      method: method,
      data: formData,
      url: url,
    });
    if (response.data === "") {
      if (response.headers?.location !== undefined)
        return { location: response.headers.location };
      return response.data;
    } else {
      const deserializer = new Deserializer({
        // api keys are underscore_case anyway so
        // don't do any transformation on incoming keys
        keyForAttribute: (str) => str,
        //keyForAttribute: "underscore_case",
      });
      const deserializedData = await deserializer.deserialize(response.data);
      if (meta) return {meta: response.data.meta, data: deserializedData}
      return deserializedData;
    }
  } catch (error) {
    // TODO: send error message to sentry
    let errorMsg = error.message;
    if (error.response) {
      if (error.response.status === 500) errorMsg = "API Internal Server Error";
      else errorMsg = error.response.data.error;
    }
    return { errorMsg };
  }
}

export function loginAdmin(data) {
  return callAPI("/thermal_admin_login", "post", data);
}

export function postTwoFactorCode(data) {
  return callAPI("/thermal_admin_login/submit_two_factor_code", "post", data);
}

export function resetPassword(data) {
  return callAPI("/thermal_admin_reset_password", "post", data);
}

export function changePassword(data) {
  return callAPI("/thermal_admin_change_password", "post", data);
}

export function forgotPassword(data) {
  return callAPI("/thermal_admin_forgot_password", "post", data);
}

export function getBookings() {
  return callAPI("/bookings");
}

export function getBooking(id) {
  return callAPI("/bookings/" + id);
}

export function updateBooking(id, data) {
  return callAPI("/bookings/" + id, "put", data);
}

export function approveBooking(id) {
  return callAPI(`/bookings/${id}/approve`, "post", {});
}

export function declineBooking(id) {
  return callAPI(`/bookings/${id}/deny`, "post", {});
}

export function cancelBooking(id) {
  return callAPI(`/bookings/${id}/cancel`, "post", {});
}

export function expireBooking(id, sendToGuide) {
  return callAPI(`/bookings/${id}/expire`, "post", {
    send_to_guide: sendToGuide,
  });
}

export function deleteBooking(id) {
  return callAPI("/bookings/" + id, "delete");
}

export function sendPreviewApprovedEmail(id) {
  return callAPI(`/bookings/${id}/preview_approved_email`, "post", {});
}

export function sendPreviewRequestedEmail(id) {
  return callAPI(`/bookings/${id}/preview_requested_email`, "post", {});
}

export function sendPreviewConfirmedEmail(id) {
  return callAPI(`/bookings/${id}/preview_confirmed_email`, "post", {});
}

export function guideRequest(id) {
  return callAPI(`/bookings/${id}/guide_request`, "post", {});
}

export function sendReminderReservationToGuide(id, data) {
  return callAPI(`/bookings/${id}/guide_request_reminder`, "post", data);
}

export function guidePayment(id, notes) {
  return callAPI(`/bookings/${id}/guide_payment`, "post", {
    payout_notes: notes,
  });
}

export function createBooking(data) {
  return callAPI("/bookings", "post", data);
}

export function getTrips() {
  return callAPI("/trips");
}

export function getTrip(id) {
  return callAPI("/trips/" + id);
}

export function createTrip(data) {
  return callAPI("/trips", "post", data);
}

export function updateTrip(id, data) {
  return callAPI("/trips/" + id, "put", data);
}

export function deleteTripMediaImages(data) {
  return callAPI("/trip_media_images/delete_images", "post", data);
}

export function updateTripMediaImagePositions(data) {
  return callAPI("/trip_media_images/update_positions", "post", data);
}

export function deleteTripAccommodationImages(data) {
  return callAPI("/trip_accommodation_images/delete_images", "post", data);
}

export function updateTripAccommodationImagePositions(data) {
  return callAPI("/trip_accommodation_images/update_positions", "post", data);
}

export function deleteTripDescriptionImages(data) {
  return callAPI("/trip_description_images/delete_images", "post", data);
}

export function updateTripDescriptionImagePositions(data) {
  return callAPI("/trip_description_images/update_positions", "post", data);
}

export function deleteTrip(id) {
  return callAPI("/trips/" + id, "delete");
}

export function getUsers() {
  return callAPI("/users");
}

export function getAdmins() {
  return callAPI("/thermal_admins");
}

// admin ID pulled from JWT
export function getAdmin() {
  return callAPI("/thermal_admins/" + "123456");
}

// admin ID pulled from JWT
export function updateThermalAdmin(data) {
  return callAPI("/thermal_admins/" + "123456", "put", data);
}

export function getOperators() {
  return callAPI("/operators");
}

export function deleteOperator(id) {
  return callAPI("/operators/" + id, "delete");
}

export function getOperator(id) {
  return callAPI("/operators/" + id);
}

export function createOperator(data) {
  return callAPI("/operators", "post", data);
}

export function updateOperator(id, data) {
  return callAPI("/operators/" + id, "put", data);
}

export function sendOperatorTestEmail(id) {
  return callAPI(`/operators/${id}/send_test_email`, "post", {});
}

export function getAccommodations() {
  return callAPI("/accommodations");
}

export function deleteAccommodation(id) {
  return callAPI("/accommodations/" + id, "delete");
}

export function createAccommodation(data) {
  return callAPI("/accommodations", "post", data);
}

export function getAccommodation(id) {
  return callAPI("/accommodations/" + id);
}

export function updateAccommodation(id, data) {
  return callAPI("/accommodations/" + id, "put", data);
}

export function deleteAccommodationImages(data) {
  return callAPI("/accommodation_images/delete_images", "post", data);
}

export function updateAccommodationImagePositions(data) {
  return callAPI("/accommodation_images/update_positions", "post", data);
}

export function getProducts(tripID) {
  return callAPI("/products?trip_id=" + tripID);
}

export function getProduct(id) {
  return callAPI("/products/" + id);
}

export function createProduct(data) {
  return callAPI("/products", "post", data);
}

export function deleteProduct(id) {
  return callAPI("/products/" + id, "delete");
}

export function deleteProductImages(data) {
  return callAPI("/product_images/delete_images", "post", data);
}

export function updateProduct(id, data) {
  return callAPI("/products/" + id, "put", data);
}

export function updateProductImagePositions(data) {
  return callAPI("/product_images/update_positions", "post", data);
}

export function createVariant(data) {
  return callAPI("/variants", "post", data);
}

export function deleteVariant(id) {
  return callAPI("/variants/" + id, "delete");
}

export function updateVariant(id, data) {
  return callAPI("/variants/" + id, "put", data);
}

export function changeVariantPricing(data) {
  return callAPI("/variants/change_pricing", "post", data);
}

export function changeVariantAvailability(data) {
  return callAPI("/variants/change_availability", "post", data);
}

export function createTripBreak(data) {
  return callAPI("/trip_breaks", "post", data);
}

export function deleteTripBreak(id) {
  return callAPI("/trip_breaks/" + id, "delete");
}

export function updateTripBreak(id, data) {
  return callAPI("/trip_breaks/" + id, "put", data);
}

export function getMessageThreads() {
  return callAPI("/messages");
}

export function getMessage(id) {
  return callAPI(`/messages/${id}`);
}

export function createMessage(data) {
  return callAPI("/messages", "post", data);
}

export function updateMessage(id, data) {
  return callAPI("/messages/" + id, "put", data);
}

export function sendMessageToGuide(id) {
  return callAPI(`/messages/${id}/send_to_guide`, "post", {});
}

export function sendReminderMessageToGuide(id, data) {
  return callAPI(`/messages/${id}/send_to_guide_reminder`, "post", data);
}

export function resolveMessageThread(id) {
  return callAPI(`/messages/${id}/resolve_message_thread`, "post", {});
}

export function createRefund(data) {
  return callAPI("/refunds", "post", data);
}

export function getUser(id) {
  return callAPI("/users/" + id);
}

export function getPromotions() {
  return callAPI("/promotions");
}

export function deletePromotion(id) {
  return callAPI("/promotions/" + id, "delete");
}

export function createPromotion(data) {
  return callAPI("/promotions", "post", data);
}

export function updatePromotion(id, data) {
  return callAPI("/promotions/" + id, "put", data);
}

export function createCredit(data) {
  return callAPI("/credits", "post", data);
}

export function createBookingNote(id, data) {
  data.booking_id = id;
  return callAPI("/booking_admin_notes", "post", data);
}

export function getRegions() {
  return callAPI("/regions");
}

export function updateRegion(id, data) {
  return callAPI("/regions/" + id, "put", data);
}

export function getAirports() {
  return callAPI("/airports");
}

export function getScouts() {
  return callAPI("/scout_spots");
}

export function expireScoutSpot(id) {
  return callAPI(`/scout_spots/${id}/expire`, "post");
}

export function getReviews() {
  return callAPI("/trip_reviews");
}

export function deleteReview(id) {
  return callAPI("/trip_reviews/" + id, "delete");
}

export function getReview(id) {
  return callAPI("/trip_reviews/" + id);
}

export function createReview(data) {
  return callAPI("/trip_reviews", "post", data);
}

export function updateReview(id, data) {
  return callAPI("/trip_reviews/" + id, "put", data);
}

export function deleteReviewImages(data) {
  return callAPI("/trip_review_images/delete_images", "post", data);
}

export function updateReviewImagePositions(data) {
  return callAPI("/trip_review_images/update_positions", "post", data);
}

export function getCollections() {
  return callAPI("/collections");
}

export function createCollection(data) {
  return callAPI("/collections", "post", data);
}

export function getCollection(id) {
  return callAPI("/collections/" + id);
}

export function updateCollection(id, data) {
  return callAPI("/collections/" + id, "put", data);
}

export function updateCollectionImagePositions(data) {
  return callAPI("/collection_images/update_positions", "post", data);
}

export function deleteCollection(id) {
  return callAPI("/collections/" + id, "delete");
}

export function deleteCollectionImages(data) {
  return callAPI("/collection_images/delete_images", "post", data);
}

export function getCollectionTags() {
  return callAPI("/collection_tags");
}

export function getSurfFeatureTags() {
  return callAPI("/surf_feature_tags");
}

export function getAccommodationFeatureTags() {
  return callAPI("/accommodation_feature_tags");
}

export function getCategoryTags() {
  return callAPI("/category_tags");
}

export function getAdventureBookings(guideID=null) {
  let url = "/adventure_bookings";
  if (guideID !== null) url += "?guide_id=" + guideID;
  return callAPI(url);
}

export function getAdventureBooking(id) {
  return callAPI("/adventure_bookings/" + id);
}

export function createAdventureBooking(data) {
  return callAPI("/adventure_bookings", "post", data);
}

export function getAdventures() {
  return callAPI("/adventures");
}

export function getAdventure(id) {
  return callAPI("/adventures/" + id);
}

export function updateAdventureBooking(id, data) {
  return callAPI("/adventure_bookings/" + id, "put", data);
}

export function createAdventureWirePayment(id, data) {
  return callAPI(`/adventure_bookings/${id}/create_wire_payment`, "post", data);
}

export function createAdventureRefund(data) {
  return callAPI("/adventure_refunds", "post", data);
}

export function cancelAdventureBooking(id) {
  return callAPI(`/adventure_bookings/${id}/cancel`, "post", {});
}

export function expireAdventureBooking(id) {
  return callAPI(`/adventure_bookings/${id}/expire`, "post", {});
}

export function unexpireAdventureBooking(id) {
  return callAPI(`/adventure_bookings/${id}/unexpire`, "post", {});
}

export function makeBookableAdventureBooking(id) {
  return callAPI(`/adventure_bookings/${id}/make_bookable`, "post", {});
}

export function deleteAdventureBooking(id) {
  return callAPI("/adventure_bookings/" + id, "delete");
}

export function createAdventure(data) {
  return callAPI("/adventures", "post", data);
}

export function updateAdventure(id, data) {
  return callAPI("/adventures/" + id, "put", data);
}

export function getGuides() {
  return callAPI("/guides");
}

export function createGuide(data) {
  return callAPI("/guides", "post", data);
}

export function getGuide(id) {
  return callAPI("/guides/" + id);
}

export function updateGuide(id, data) {
  return callAPI("/guides/" + id, "put", data);
}

export function getInineraryAttributes() {
  return callAPI("/itinerary_attributes");
}

export function getAdventureRequests() {
  return callAPI("/adventure_requests");
}

export function deleteAdventureMediaImages(data) {
  return callAPI("/adventure_media_images/delete_images", "post", data);
}

export function updateAdventureMediaImagePositions(data) {
  return callAPI("/adventure_media_images/update_positions", "post", data);
}

export function deleteAdventureSurfImages(data) {
  return callAPI("/adventure_surf_images/delete_images", "post", data);
}

export function updateAdventureSurfImagePositions(data) {
  return callAPI("/adventure_surf_images/update_positions", "post", data);
}

export function getAdventureReviews() {
  return callAPI("/adventure_reviews");
}

export function deleteAdventureReview(id) {
  return callAPI("/adventure_reviews/" + id, "delete");
}

export function getAdventureReview(id) {
  return callAPI("/adventure_reviews/" + id);
}

export function createAdventureReview(data) {
  return callAPI("/adventure_reviews", "post", data);
}

export function updateAdventureReview(id, data) {
  return callAPI("/adventure_reviews/" + id, "put", data);
}

export function deleteAdventureReviewImages(data) {
  return callAPI("/adventure_review_images/delete_images", "post", data);
}

export function updateAdventureReviewImagePositions(data) {
  return callAPI("/adventure_review_images/update_positions", "post", data);
}

export function createAdventureHighlight(data) {
  return callAPI("/adventure_highlights", "post", data);
}

export function deleteAdventureHighlight(id) {
  return callAPI("/adventure_highlights/" + id, "delete");
}

export function updateAdventureHighlight(id, data) {
  return callAPI("/adventure_highlights/" + id, "put", data);
}

export function createAdventureWebsiteAccommodation(data) {
  return callAPI("/adventure_website_accommodations", "post", data);
}

export function deleteAdventureWebsiteAccommodation(id) {
  return callAPI("/adventure_website_accommodations/" + id, "delete");
}

export function updateAdventureWebsiteAccommodation(id, data) {
  return callAPI("/adventure_website_accommodations/" + id, "put", data);
}

export function getGuideAvailabilities(guideID) {
  return callAPI("/guide_availabilities/" + guideID, "get", undefined, true);
}

// data needs start date, end date
export function blockAvailableDates(data, guideID) {
  return callAPI(`/guide_availabilities/${guideID}/block_available_dates`, "post", data);
}

// data needs start date, end date
export function unblockAvailableDates(data, guideID) {
  return callAPI(`/guide_availabilities/${guideID}/unblock_available_dates`, "post", data);
}

export function getEventBookings() {
  return callAPI("/event_bookings");
}

export function getEventBooking(id) {
  return callAPI("/event_bookings/" + id);
}

export function updateEventBooking(id, data) {
  return callAPI("/event_bookings/" + id, "put", data);
}

export function cancelEventBooking(id) {
  return callAPI(`/event_bookings/${id}/cancel`, "post", {});
}

export function expireEventBooking(id) {
  return callAPI(`/event_bookings/${id}/expire`, "post", {});
}

export function unexpireEventBooking(id) {
  return callAPI(`/event_bookings/${id}/unexpire`, "post", {});
}

export function makeBookableEventBooking(id) {
  return callAPI(`/event_bookings/${id}/make_bookable`, "post", {});
}

export function deleteEventBooking(id) {
  return callAPI("/event_bookings/" + id, "delete");
}

export function getEventVariants(guideID=null) {
  let url = "/event_variants";
  if (guideID !== null) url += "?guide_id=" + guideID;
  return callAPI(url);
}

export function createEventWirePayment(id, data) {
  return callAPI(`/event_bookings/${id}/create_wire_payment`, "post", data);
}

export function createEventRefund(data) {
  return callAPI("/event_refunds", "post", data);
}

export function createEventBooking(data) {
  return callAPI("/event_bookings", "post", data);
}

export function deleteAccommodationsNotesImages(data) {
  return callAPI("/accommodations_notes_images/delete_images", "post", data);
}

export function updateAccommodationsNotesImagePositions(data) {
  return callAPI("/accommodations_notes_images/update_positions", "post", data);
}

export function getEvents() {
  return callAPI("/events");
}

export function getEvent(id) {
  return callAPI("/events/" + id);
}

export function updateEvent(id, data) {
  return callAPI("/events/" + id, "put", data);
}

export function createEventVariant(data) {
  return callAPI("/event_variants", "post", data);
}

export function deleteEventVariant(id) {
  return callAPI("/event_variants/" + id, "delete");
}

export function updateEventVariant(id, data) {
  return callAPI("/event_variants/" + id, "put", data);
}
