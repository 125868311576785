import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import {
  getEvent,
  updateEvent,
} from "lib/api";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useLoadingContext } from "context/loading-context";
import { useToasts } from "react-toast-notifications";
import { FormProvider } from "context/form-context";
import { humanDate } from "helpers/formatting";
import EventVariantsTable from "./EventVariantsTable";
import Meta from "lunar/Meta";
import Form from "lunar/Form";
import FormTitle from "lunar/FormTitle";
import FormSections from "lunar/FormSections";
import FormSection from "lunar/FormSection";
import FormSectionHeader from "lunar/FormSectionHeader";
import FormSectionBody from "lunar/FormSectionBody";

export default function Edit() {
  const [event, setEvent] = useState({});
  const eventID = useParams().id;
  const { addToast } = useToasts();
  const { setLoading } = useLoadingContext();

  const {
    // register,
    // errors,
    reset,
    handleSubmit,
    // setValue,
    // getValues,
    // control,
    resetField,
    formState: { isSubmitting },
  } = useForm();

  useEffect(() => {
    if (event) {
      reset({
        name: event.name,
      });
    }
  }, [reset, event]);

  useEffect(() => {
    (async () => {
      populateEventState(eventID);
    })();
  }, [eventID]);

  async function populateEventState(eventID) {
    setLoading(true);
    const response = await getEvent(eventID);
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      setEvent(response);
    }
    setLoading(false);
  }

  function handleSuccess(msg) {
    populateEventState(eventID);
    addToast(msg, { appearance: "success" });
  }

  function handleError(msg) {
    addToast(msg, { appearance: "error" });
  }

  async function submitForm(data) {
    const response = await updateEvent(eventID, data);
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      handleSuccess("Event successfully updated");
      // remove images/videos from input type file so no re-uploaded on next submission
      for (const [key, val] of Object.entries(data)) {
        if (val instanceof FileList) resetField(key);
      }
    }
  }

  return (
    <>
      <Meta title={event.name} />
      <FormProvider
        lastUpdated={event.updated_at && humanDate(event.updated_at)}
      >
        <DndProvider backend={HTML5Backend}>
          <Form
            handleSubmit={handleSubmit}
            submitForm={submitForm}
            isSubmitting={isSubmitting}
          >
            <FormTitle title={event.name || ""} />
            <FormSections>
              <FormSection>
                <FormSectionHeader
                  title="Departures"
                  description="The different dates for this event."
                />
                <FormSectionBody className="grid gap-y-6">
                  <EventVariantsTable
                    eventID={eventID}
                    variants={event.event_variants}
                    handleError={handleError}
                    handleSuccess={handleSuccess}
                  />
                </FormSectionBody>
              </FormSection>
            </FormSections>
          </Form>
        </DndProvider>
      </FormProvider>
    </>
  );
}
